body {
  margin: 0;
  font-family: Glass, Consolas, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  margin: 0;
  font-family: Glass, Consolas, monospace;
}

@font-face {
  font-family: 'Glass';
  src: local('Glass'), url(./fonts/Glass_TTY_VT220.ttf) format('truetype');
}